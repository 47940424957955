<template>
    <div class="wrap">
        <div class="breadcrumb">
            <el-breadcrumb
                separator="/"
            >
                <el-breadcrumb-item :to="{ path: '/promotion/goods' }">考拉赚客</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/accountManagement">账号管理</a></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div v-show="zhuankeStatus == 1" class="accountUnnormal">
            <div data-v-3fc699a7="" role="alert" class="el-alert el-alert--warning is-light">
                <i class="el-alert__icon el-icon-warning" /><div class="el-alert__content">
                    <span class="el-alert__title">
                        当前赚客帐号异常，请联系 caixi.cx@alibaba-inc.com 或前往
                        <a style="color:blue;text-decoration: underline" href="/help">帮助中心
                        </a>
                        &gt;</span>
                </div>
            </div>
        </div>
        <div class="userBaseInfo">
            <h3>
                赚客基础信息
            </h3>
            <el-divider />
            <div class="BaseInfo">
                <div class="BaseInfo_one">
                    <span>
                        赚客ID：
                    </span>
                    <span>
                        {{ zhuankeId || "-" }}
                    </span>
                </div>
                <div class="BaseInfo_one" style="margin-right: 20px;" v-if="zhuankeType === 1">
                    <span>AppSecret：</span>
                    <span>
                        {{ appSecret || "-" }}
                    </span>
                </div>

                <div class="BaseInfo_one">
                    <span>赚客类型：</span>
                    <span>
                        {{ formatType(zhuankeType) }}
                    </span>
                </div>
            </div>
        </div>
        <div class="userBaseInfo">
            <h3>
                账号信息
            </h3>
            <el-divider />
            <div class="BaseInfo" style="height:55px">
                <div class="BaseInfo_one">
                    <span>
                        账户昵称：
                    </span>
                    <span>
                        {{ kaolaNickName || "-" }}
                    </span>
                </div>
                <div class="BaseInfo_one" v-if="zhuankeType === 1">
                    <span>邮箱：</span>
                    <span>
                        {{ kaolaEmailAddress || "-" }}
                    </span>
                </div>

                <div class="BaseInfo_one">
                    <span>手机：</span>
                    <span>
                        {{ kaolaPhoneNum || "-" }}
                    </span>
                </div>
                <div class="tip">
                    <div data-v-3fc699a7="" role="alert" class="el-alert el-alert--info is-light">
                        <i class="el-alert__icon el-icon-info" /><div class="el-alert__content">
                            <span class="el-alert__title">
                                提示：用户个人信息，可前往“
                                <a style="color:red" :href="personalCenterUrl" target="view_window">考拉-个人中心
                                </a>
                                <a :href="personalCenterUrl" target="view_window">” 修改 &gt;</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="userBaseInfo" v-if="zhuankeType === 1">
            <h3>
                企业资质信息
            </h3>
            <el-divider />
            <div class="companyInfo-area">
                <infoForm v-if="companyQualInfo"
                          :form-data="companyQualInfo" :hidesensitive-info="false"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import infoForm from '../../components/companyQualAduit/infoForm';

export default {
    data(){
        return{
            personalCenterUrl: 'https://user.kaola.com/personal/account_management.html'
        };
    },
    components: {
        infoForm
    },
    computed: {
        ...mapState([
            'isLogin',
            'kaolaEmailAddress',
            'kaolaHeadImageUrl',
            'kaolaNickName',
            'kaolaPhoneNum',
            'zhuankeType',
            'zhuankeId',
            'appSecret',
            'zhuankeStatus',
            'zhuankeLevel',
            'companyQualInfo'
        ])
    },

    methods: {
        formatType(type){
            if(`${type}` === '0') {
                return '个人赚客';
            }
            return '企业赚客';
        }
    }
};
</script>
<style lang="scss">
.breadcrumb .el-breadcrumb{
    font-size: 12px
}
.userBaseInfo .el-divider--horizontal{
    margin: 12px 0;
}
</style>
<style lang="scss" scoped>
.wrap{
    width: 100%;
    height: 100%;
    .breadcrumb{
        background: #ffffff;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 10px;
    }
    .userBaseInfo{
        padding: 20px;
        padding-left: 30px;
        background: #ffffff;
        margin-bottom: 10px;
    }
    .BaseInfo{
        width: 100%;
        position: relative;
        display: flex;
    }
    .BaseInfo_one{
        display: inline-block;
        min-width: 28%;
    }
    .tip{
        position: absolute;
        left: 0;
        top: 30px;
    }
    .gotoUserInfo{
        width: 15px;
        height: 10px;
    }
    .accountUnnormal{
        margin-bottom: 10px;
    }
    .companyInfo-area{
        max-width: 520px;
    }
}

</style>
